import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Notification {
  id?: number; // will be assigned automatically
  message: string | null;
  type: 'success' | 'error' | 'warning' | 'default';
  timestamp: string | null;
  traceId?: string | null;
  details?: { [key: string]: any } | null; // additional details
}

interface NotificationState {
  id?: number; // will be assigned automatically
  message: string | null;
  type: 'success' | 'error' | 'warning' | 'default';
  timestamp: string | null;
  traceId?: string | null;
  details?: { [key: string]: any } | null; // additional details
  allNotifications: Notification[];
}

let savedNotifications: Notification[] = [];
if (typeof window !== 'undefined' && localStorage.getItem('allNotifications')) {
  savedNotifications = JSON.parse(localStorage.getItem('allNotifications') || '[]');
}

const initialState: NotificationState = {
  message: null,
  type: 'default',
  timestamp: null,
  traceId: null,
  details: null,
  allNotifications: savedNotifications,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (
      state,
      action: PayloadAction<{
        message: string;
        type: 'success' | 'error' | 'warning' | 'default';
        traceId?: string | null;
        details?: { [key: string]: any } | null;
      }>
    ) => {
      const newNotification: Notification = {
        id: state.allNotifications.length + 1,
        message: action.payload.message,
        type: action.payload.type,
        timestamp: new Date().toISOString(),
        traceId: action.payload.traceId,
        details: action.payload.details || null,
      };

      state.message = action.payload.message;
      state.type = action.payload.type;
      state.timestamp = newNotification.timestamp;
      state.traceId = action.payload.traceId;
      state.details = action.payload.details || null;
      if (state.type === 'error' || state.type === 'warning') {
        // only store error notifications in history
        state.allNotifications.push(newNotification);
      }

      if (typeof window !== 'undefined') {
        localStorage.setItem('allNotifications', JSON.stringify(state.allNotifications));
      }
    },
    clearNotification: (state) => {
      state.message = null;
      state.type = 'default';
      state.timestamp = null;
      state.traceId = null;
      state.details = null;
    },
    clearAllNotifications: (state) => {
      state.allNotifications = [];
      if (typeof window !== 'undefined') {
        localStorage.setItem('allNotifications', JSON.stringify(state.allNotifications));
      }
    },
  },
});

export const { setNotification, clearNotification, clearAllNotifications } = notificationSlice.actions;

export { initialState };

export default notificationSlice.reducer;
