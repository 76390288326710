import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { handleApiError } from "@/utils/error-handler";
import { setApplicationVersion, useApplicationVersions } from '@/store/customerManagement';

export type VersionInfo = {
  api: string;
  ui: string;
};

const getReleaseVersion = (input: string): string => {
  if (input.indexOf("/")) {
    return input.split("/")[1];
  }
  return input;
};

function useServiceVersion(): VersionInfo {
  const dispatch = useDispatch();
  const { api: storedApiVersion, ui: storedUiVersion } = useApplicationVersions();
  const [apiVersion, setApiVersion] = useState<string>(storedApiVersion ? String(storedApiVersion) : "");
  const [uiVersion, setUiVersion] = useState<string>(storedUiVersion ? String(storedUiVersion) : "");

  useEffect(() => {
    const fetchApiVersion = async (): Promise<void> => {
      // removed for now
      // if (!storedApiVersion) {
      //   try {
      //     const response = await fetch(`/api/info-api`);
      //     const json = await response.json();
      //     if (json.git.branch) {
      //       const version = getReleaseVersion(json.git.branch);
      //       setApiVersion(version);
      //       dispatch(setApplicationVersion({ api: version, ui: storedUiVersion }));
      //     }
      //   } catch (error) {
      //     handleApiError(error as any);
      //   }
      // }
      setApiVersion('');
    };

    const fetchUiVersion = async (): Promise<void> => {
      if (!storedUiVersion) {
        try {
          const response = await fetch(`/api/info`);
          const json = await response.json();
          if (json.git.branch) {
            const version = getReleaseVersion(json.git.branch);
            setUiVersion(version);
            dispatch(setApplicationVersion({ api: storedApiVersion, ui: version }));
          }
        } catch (error) {
          setUiVersion("1.0.0");
          // handleApiError(error as any);
        }
      }
    };

    fetchApiVersion();
    /**
     * disabled for now to prevent errors in dev
     */
    // fetchUiVersion();
  }, [storedApiVersion, storedUiVersion, dispatch]);

  return { api: apiVersion, ui: uiVersion };
}

export default useServiceVersion;
