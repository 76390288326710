import { UserRole } from '@/types/UserRole';
import { AdvancedSearchParams, DataTableState, OrderManagementState, TableState } from './customerManagement';
import { DEFAULT_PAGE_SIZE } from '@/utils/constants';
import { Page, Role, RoleType } from '../generated';
import { TableType } from '@/types/Table';
import SystemSettings, { SystemSettingsConfig } from '@/service/system-settings';


const localEnvRoleString = process.env.NEXT_PUBLIC_LOCAL_ROLE;
export const localEnvRole = localEnvRoleString ? JSON.parse(localEnvRoleString) as Role : undefined;

export const defaultSort = ['lastModifiedDate,DESC'];
export const defaultPageable = { page: 0, size: DEFAULT_PAGE_SIZE }; //, sort: ['updateAt,DESC'] };
export const emptyPage: Page = { content: [], totalPages: 0, totalElements: 0, size: 0, number: 0, numberOfElements: 0 };
export const emptyTableState: TableState = {
  currentPage: { content: undefined, totalPages: 0 },
  pageSize: DEFAULT_PAGE_SIZE,
  sortKeys: [',ASC']
}
export const initialCustomerTable: TableState = {
  currentPage: { content: undefined, totalPages: 0, totalElements: 0, size: 0, number: 0, numberOfElements: 0 },
  pageSize: DEFAULT_PAGE_SIZE,
  sortKeys: ['id,ASC'],
  advancedSearchParams: {},
  advancedSearchPanelState: false,
  generalSearchQuery: {}
};
export const initialDeviceTable: TableState = {
  currentPage: { content: undefined, totalPages: 0, totalElements: 0, size: 0, number: 0, numberOfElements: 0 },
  pageSize: DEFAULT_PAGE_SIZE,
  sortKeys: ['updatedAt,DESC'],
  advancedSearchParams: {},
  advancedSearchPanelState: false,
  generalSearchQuery: {}
};
export const initialUserTable: TableState = {
  currentPage: { content: undefined, totalPages: 0, totalElements: 0, size: 0, number: 0, numberOfElements: 0 },
  pageSize: DEFAULT_PAGE_SIZE,
  sortKeys: ['username,ASC'],
  advancedSearchParams: {},
  advancedSearchPanelState: false,
  generalSearchQuery: {},
  cachedData: []
};
export const initialTelusUserTable: TableState = {
  currentPage: { content: undefined, totalPages: 0, totalElements: 0, size: 0, number: 0, numberOfElements: 0 },
  pageSize: DEFAULT_PAGE_SIZE,
  sortKeys: ['name,ASC'],
  advancedSearchParams: {},
  advancedSearchPanelState: false,
  generalSearchQuery: {},
  cachedData: []
};


const initializeDataTableState = () => {
  const initialState: DataTableState = {}; 
  Object.values(TableType).forEach(type => {
    if (type === TableType.CUSTOMER_LIST) {
      initialState[type] = { ...initialCustomerTable };
    } else if (type === TableType.DEVICE_LIST) {
      initialState[type] = { ...initialDeviceTable };
    } else if (type === TableType.USER_LIST) {
      initialState[type] = { ...initialUserTable };
    } else if (type === TableType.TELUS_USER_LIST) {
      initialState[type] = { ...initialTelusUserTable };
    }else {
      initialState[type] = { ...emptyTableState };
    }
  });
  return initialState;
};

export const omInitialState: OrderManagementState = {
  currentPageable: defaultPageable,
  currentCustomerAuditLog: {},
  deviceUploadPreview: [],
  selectedCustomer: null,
  user: null,
  user2FAEnable: false,
  allowedCustomerUserRoles: [],
  allowedTelusUserRoles: [],
  telusUserRoles: [],
  customerUserRoles: [],
  settings: {
    refreshSite: {
      siteId: undefined,
      refresh: false
    },
    defaultLocale: 'en',
    toolbar: {
      selectedSliceIds: []
    },
    tableType: null,
    createSliceModalOpen: false,
    isBeingSubmitted: false,
    isSubmitted: false,
    isSuccess: false,
    successMessage: null,
    errorMessage: null,
    isLoading: false,
    others: {},
    advancedSearchPanelState: false,
    disableSyncButton: {},
    deploymentPlatform: null,
    siteLoading: false
  },
  notifications: { },
  favouriteSite: { siteId: null, siteName: ''},
  dispatch: (state: any) => { },
  advancedSearchParams: {
    customerName: "",
    id: "",
    ban: "",
    customerStatus: [],
    name: "",
    role: {
      label: ''
    },
    status: [],
    username: "",
    imsi: "",
    msisdn: "",
    profileName: "",
    siteName: [],
    dateActivated: "",
    createdAt: ""
  } as AdvancedSearchParams,
  generalSearchQuery: {
    searchQuery: ""
  },
  dataTableState: initializeDataTableState(), // DataTable Page Initialization
  applicationVersions: {
    api: "",
    ui: "",
  }
}

export const localNoLoginState: OrderManagementState = {
  ...omInitialState,
  settings: {
    ...omInitialState.settings
  },
  user: {
    id: '',
    name: 'Chandra Poluru',
    username: '',
    email: 'chandra.poluru@telus.com',
    role: localEnvRole ||
    {
      id: "1",
      name: UserRole.TELUS_SYSTEM_ADMIN,
      label: 'Telus System Admin',
      type: 'TELUS',
      order: 1
    } as Role,
    expiryTime: 9713396670655,
    isAuthenticated: true,
    sites: []
  },
  user2FAEnable: false,
  allowedCustomerUserRoles: [],
  allowedTelusUserRoles: [],
  telusUserRoles: [],
  customerUserRoles: []
}
