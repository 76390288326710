/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SiteImeiLockRequest = {
    properties: {
        imeiLockEnabled: {
            type: 'boolean',
        },
        imeiType: {
            type: 'ImeiType',
        },
        imeiAction: {
            type: 'string',
        },
        fileUploadType: {
            type: 'FileUploadType',
        },
    },
} as const;
