/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Usim = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        imsi: {
            type: 'string',
        },
        msisdn: {
            type: 'string',
        },
        externalId: {
            type: 'number',
            format: 'int64',
        },
        batchNo: {
            type: 'string',
        },
        iccid: {
            type: 'string',
        },
        profileCode: {
            type: 'string',
        },
        pin1: {
            type: 'string',
        },
        puk1: {
            type: 'string',
        },
        pin2: {
            type: 'string',
        },
        puk2: {
            type: 'string',
        },
        usimSites: {
            type: 'array',
            contains: {
                type: 'UsimSite',
            },
        },
        customer: {
            properties: {
            },
        },
        error: {
            type: 'string',
        },
    },
} as const;
