import SystemSettings from "@/service/system-settings";
import { useAppSettings, useIsAutneticated } from "@/store/customerManagement"
import { ActivityIndicator, Spacer } from "@telus-uds/components-web"
import { ReactNode } from "react";

interface LoggedInWrapperProps {
  children: ReactNode | ReactNode[]
}

const LoggedInWrapper = ({ children }: LoggedInWrapperProps) => {
  const busy = !useIsAutneticated()
  return (
    <>
      {busy
        ? <>
          <Spacer space={3}/>
          <ActivityIndicator label={'Loading'} variant={{ size: 'large' }} />
        </>
        : <>
          {children}
        </>
      }
    </>
  )
}

export default LoggedInWrapper
