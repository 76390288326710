/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum UsimEvent {
    SITE_PROVISION = 'SITE_PROVISION',
    PROFILE_UPDATE = 'PROFILE_UPDATE',
    SIM_STATUS_CHANGE = 'SIM_STATUS_CHANGE',
    DELETED = 'DELETED',
    BLOCKED = 'BLOCKED',
    DISABLED = 'DISABLED',
}
