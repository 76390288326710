/** How to use this component:
 *  - For api handling: utilize error-handler.ts to handle api errors, logs come here
 *  - On a page that needs to submit a notification, dispatch to the notification redux
 *  - Queue holds 5 unique notifications at a time
 */

import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Notification, Spacer } from '@telus-uds/components-web';
import { clearNotification } from "@/store/submitNotification";
import { RootState } from "../store";
import styled from 'styled-components';

const NotificationContainer = styled.div`
  position: fixed;
  top: 2%;
  width: 90%;
  z-index: 999999 !important; // High z-index to ensure it stays on top of page content
`;

const APISubmit = () => {
  const globalNotification = useSelector((state: RootState) => state.notification);
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState<Array<{ id: number; type: string; message: string }>>([]);
  const timersRef = useRef<NodeJS.Timeout[]>([]);

  // Clearing all timers when the component unmounts
  useEffect(() => {
    return () => {
      timersRef.current.forEach(timer => clearTimeout(timer));
    };
  }, []);

  useEffect(() => {
    if (globalNotification.message) {
      // const isNewMessage = notifications.length === 0 || notifications[notifications.length - 1].message !== globalNotification.message;
      if (notifications.length < 5) {
        dispatch(clearNotification());
        const newNotification = {
          id: Math.floor(Math.random() * 1000000),
          type: globalNotification.type,
          message: globalNotification.message
        };
        setNotifications(prev => [...prev, newNotification]);

        const timerId = setTimeout(() => {
          setNotifications(prev => prev.filter(notification => notification.id !== newNotification.id));
          timersRef.current = timersRef.current.filter(timer => timer !== timerId);
        }, 10000);

        // Store the timer in the ref
        timersRef.current.push(timerId);
      }
    }
  }, [globalNotification]);

  return (
    <>
      <Spacer space={2} />
      {/* {window.scrollTo(0, 0)} */}
      <NotificationContainer>

        {notifications.map(notification => (
          <Notification key={notification.id} variant={{ validation: notification.type }} dismissible>
            {notification.message}
          </Notification>
        ))}
      </NotificationContainer>

      <Spacer space={2} />
    </>
  );
}

export default APISubmit;
