export enum TableType {
  CUSTOMER_LIST = "CustomerList",
  USER_LIST = "UserList",
  DEVICE_LIST = "DeviceList",
  DEVICE_AUDIT_LOG = "DeviceAuditLog",
  DEVICE_DETAILS = "DeviceDetails",
  SIM_PROFILES = "SIM Profiles",
  APN_PROFILES = "APN Profiles",
  ADVANCED_QOS_3G_TEMPLATES = "Advanced QoS 3G Templates",
  QOS_TEMPLATES_3G = "QoS Templates 3G",
  QOS_TEMPLATES_4G = "QoS Templates 4G",
  EVENT_HISTORY = "Event History",
  SYNCHRONISATION = "Synchronisation",
  DEVICE_PREVIEW = "Device Preview",
  TELUS_USER_LIST = "TelusUserList",
  CORE_NETWORK = "CoreNetwork",
  OTHER_NETWORK_COMPONENTS = "OtherNetworkComponents",
}