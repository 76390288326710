/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum NetworkType {
    HSS = 'HSS',
    MME = 'MME',
    PGW = 'PGW',
    OTHER = 'OTHER',
}
