
export interface SystemSettingsConfig {
  CLIENT_ID: string | undefined;
  CLIENT_SECRET: string | undefined;
  SCOPE: string | undefined;
  SSO_LOGIN_URL: string | undefined;
  SSO_TOKEN_URL: string | undefined;
  SSO_LOGOUT_URL: string | undefined;
  DEPLOYMENT_PLATFORM: string | undefined;
  API_HEALTH_CHECK_BASE_URL: string | undefined;
  AUTH_HEALTH_CHECK_BASE_URL?: string | undefined; 

  authorizationCodeUrl: string;
  logoutUrl: string;
  refreshTokenUrl: string;
  homepageRedirectPath: string;
}

export default class SystemSettings {
  static settings: SystemSettingsConfig = {
    CLIENT_ID: process.env.CLIENT_ID,
    CLIENT_SECRET: process.env.CLIENT_SECRET,
    SCOPE: process.env.SCOPE,
    SSO_LOGIN_URL: process.env.SSO_LOGIN_URL,
    SSO_TOKEN_URL: process.env.SSO_TOKEN_URL,
    SSO_LOGOUT_URL: process.env.SSO_LOGOUT_URL,
    DEPLOYMENT_PLATFORM: process.env.DEPLOYMENT_PLATFORM,
    API_HEALTH_CHECK_BASE_URL: process.env.API_HEALTH_CHECK_BASE_URL,
    AUTH_HEALTH_CHECK_BASE_URL: process.env.AUTH_HEALTH_CHECK_BASE_URL,
    
    authorizationCodeUrl: '/api/auth/login',
    logoutUrl: '/api/auth/logout',
    refreshTokenUrl: '/api/auth/refresh-token',
    homepageRedirectPath: '/'
  }

  static updateSettings(partialSettings: Partial<SystemSettingsConfig>) {    
    SystemSettings.settings = {
      ...SystemSettings.settings,
      ...partialSettings
    };    
  }
}
