/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvancedQos3g } from '../models/AdvancedQos3g';
import type { AdvancedQos3gResponse } from '../models/AdvancedQos3gResponse';
import type { Apn } from '../models/Apn';
import type { ApnProfile } from '../models/ApnProfile';
import type { ApnProfileResponse } from '../models/ApnProfileResponse';
import type { BulkSimProfileUpdateRequest } from '../models/BulkSimProfileUpdateRequest';
import type { BulkSimProfileUpdateResponse } from '../models/BulkSimProfileUpdateResponse';
import type { BulkSimProvisionRequest } from '../models/BulkSimProvisionRequest';
import type { BulkSimProvisionResponse } from '../models/BulkSimProvisionResponse';
import type { BulkSimStatusUpdateRequest } from '../models/BulkSimStatusUpdateRequest';
import type { BulkSimStatusUpdateResponse } from '../models/BulkSimStatusUpdateResponse';
import type { DeviceSearchResponse } from '../models/DeviceSearchResponse';
import type { ImeiLockRequest } from '../models/ImeiLockRequest';
import type { ModifyLockedImeiRequest } from '../models/ModifyLockedImeiRequest';
import type { ModifyLockedImeiResponse } from '../models/ModifyLockedImeiResponse';
import type { NetworkCommandRequest } from '../models/NetworkCommandRequest';
import type { Page } from '../models/Page';
import type { Qos3g } from '../models/Qos3g';
import type { Qos3gResponse } from '../models/Qos3gResponse';
import type { Qos4g } from '../models/Qos4g';
import type { Qos4gResponse } from '../models/Qos4gResponse';
import type { SiteNetworkComponentRequest } from '../models/SiteNetworkComponentRequest';
import type { SiteNetworkComponentResponse } from '../models/SiteNetworkComponentResponse';
import type { SyncLogResponse } from '../models/SyncLogResponse';
import type { UsimConnectionStatusResponse } from '../models/UsimConnectionStatusResponse';
import type { UsimEventHistoryListResponse } from '../models/UsimEventHistoryListResponse';
import type { UsimProfile } from '../models/UsimProfile';
import type { UsimProfileResponse } from '../models/UsimProfileResponse';
import type { UsimSessionInfoResponse } from '../models/UsimSessionInfoResponse';
import type { UsimSiteEntryResponse } from '../models/UsimSiteEntryResponse';
import type { UsimStatus } from '../models/UsimStatus';
import type { UsimUpdateRequest } from '../models/UsimUpdateRequest';
import type { UsimUpdateResponse } from '../models/UsimUpdateResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NetworkConfigurationService {
    /**
     * Enable or Disable IMEI Locking for SIMs across all sites
     * @param customerId
     * @param siteId
     * @param lock
     * @param requestBody
     * @returns any Successfully updated IMEI locking for the SIMs
     * @throws ApiError
     */
    public static updateSimImeiLock(
        customerId: number,
        siteId: number,
        lock: boolean,
        requestBody: ImeiLockRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/usims/imei-lock/{lock}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'lock': lock,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request data`,
                404: `SIM not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves details of a specific SIM card under a customer's account
     * @param customerId
     * @param siteId
     * @param simId
     * @returns UsimSiteEntryResponse Successfully retrieved SIM details
     * @throws ApiError
     */
    public static getSimDetails(
        customerId: number,
        siteId: number,
        simId: number,
    ): CancelablePromise<UsimSiteEntryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/sims/{simId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'simId': simId,
            },
            errors: {
                400: `Bad Request`,
                404: `SIM or customer not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Updates data for a specific SIM card belonging to a customer
     * @param customerId
     * @param simId
     * @param siteId
     * @param requestBody
     * @returns UsimUpdateResponse Successfully updated the SIM status
     * @throws ApiError
     */
    public static updateUsim(
        customerId: number,
        simId: number,
        siteId: number,
        requestBody: UsimUpdateRequest,
    ): CancelablePromise<UsimUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/sims/{simId}',
            path: {
                'customerId': customerId,
                'simId': simId,
                'siteId': siteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request data`,
                404: `Customer or SIM not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Modify Locked IMEI for a SIM
     * @param customerId
     * @param siteId
     * @param simId
     * @param requestBody
     * @returns ModifyLockedImeiResponse Successfully updated Locked IMEI for the SIM
     * @throws ApiError
     */
    public static modifyLockedImei(
        customerId: number,
        siteId: number,
        simId: number,
        requestBody: ModifyLockedImeiRequest,
    ): CancelablePromise<ModifyLockedImeiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/sims/{simId}/locked-imei',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'simId': simId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request data`,
                404: `SIM not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Edit the profile of multiple SIMs
     * @param customerId
     * @param siteId
     * @param requestBody
     * @returns BulkSimProfileUpdateResponse Successfully updated the SIM profile
     * @throws ApiError
     */
    public static bulkUpdateSimProfile(
        customerId: number,
        siteId: number,
        requestBody: Array<BulkSimProfileUpdateRequest>,
    ): CancelablePromise<BulkSimProfileUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/sims/bulk-profile',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request data`,
                404: `SIM or site not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Updates an existing 4G QoS profile for a customer's site
     * @param customerId
     * @param siteId
     * @param qosId
     * @param requestBody
     * @returns Qos4g 4G QoS profile updated successfully
     * @throws ApiError
     */
    public static update4GQoSProfile(
        customerId: number,
        siteId: number,
        qosId: number,
        requestBody: Qos4g,
    ): CancelablePromise<Qos4g> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/qos/4g/{qosId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            query: {
                'qosId': qosId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `4G QoS profile not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Deletes a specific 4G QoS profile for a customer's site
     * @param customerId
     * @param siteId
     * @param qosId
     * @returns void
     * @throws ApiError
     */
    public static delete4GQoSProfile(
        customerId: number,
        siteId: number,
        qosId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/qos/4g/{qosId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'qosId': qosId,
            },
            errors: {
                400: `Bad Request`,
                404: `4G QoS profile not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Updates an existing network component for a specific customer's site
     * @param customerId
     * @param siteId
     * @param componentId
     * @param requestBody
     * @returns SiteNetworkComponentResponse Successfully updated network component
     * @throws ApiError
     */
    public static updateSiteNetworkComponent(
        customerId: number,
        siteId: number,
        componentId: number,
        requestBody: SiteNetworkComponentRequest,
    ): CancelablePromise<SiteNetworkComponentResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/components/{componentId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'componentId': componentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input data`,
                404: `Site or Component not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Deletes an existing network component for a specific customer's site
     * @param customerId
     * @param siteId
     * @param componentId
     * @returns void
     * @throws ApiError
     */
    public static deleteSiteNetworkComponent(
        customerId: number,
        siteId: number,
        componentId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/components/{componentId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'componentId': componentId,
            },
            errors: {
                400: `Bad Request`,
                404: `Site or Component not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves details of a specific APN for a customer's site
     * @param customerId
     * @param siteId
     * @param apnId
     * @returns Apn Successfully retrieved APN details
     * @throws ApiError
     */
    public static getApnDetails(
        customerId: number,
        siteId: number,
        apnId: number,
    ): CancelablePromise<Apn> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/apn/{apnId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'apnId': apnId,
            },
            errors: {
                400: `Bad Request`,
                404: `APN not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Updates an existing APN configuration for a customer's site
     * @param customerId
     * @param siteId
     * @param apnId
     * @param requestBody
     * @returns Apn APN configuration updated successfully
     * @throws ApiError
     */
    public static updateApn(
        customerId: number,
        siteId: number,
        apnId: number,
        requestBody: Apn,
    ): CancelablePromise<Apn> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/apn/{apnId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'apnId': apnId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `APN not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Deletes a specific APN for a customer's site
     * @param customerId
     * @param siteId
     * @param apnId
     * @returns void
     * @throws ApiError
     */
    public static deleteApn(
        customerId: number,
        siteId: number,
        apnId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/apn/{apnId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'apnId': apnId,
            },
            errors: {
                400: `Bad Request`,
                404: `APN not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves details of a specific APN profile for a customer's site
     * @param customerId
     * @param siteId
     * @param profileId
     * @returns ApnProfileResponse Successfully retrieved APN profile details
     * @throws ApiError
     */
    public static getApnProfileDetails(
        customerId: number,
        siteId: number,
        profileId: number,
    ): CancelablePromise<ApnProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/apn/profile/{profileId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'profileId': profileId,
            },
            errors: {
                400: `Bad Request`,
                404: `APN profile not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Updates an existing APN profile for a customer's site
     * @param customerId
     * @param siteId
     * @param profileId
     * @param requestBody
     * @returns ApnProfile APN profile updated successfully
     * @throws ApiError
     */
    public static updateApnProfile(
        customerId: number,
        siteId: number,
        profileId: number,
        requestBody: ApnProfile,
    ): CancelablePromise<ApnProfile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/apn/profile/{profileId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'profileId': profileId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `APN profile not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Deletes a specific APN profile for a customer's site
     * @param customerId
     * @param siteId
     * @param profileId
     * @returns void
     * @throws ApiError
     */
    public static deleteApnProfile(
        customerId: number,
        siteId: number,
        profileId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/apn/profile/{profileId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'profileId': profileId,
            },
            errors: {
                400: `Bad Request`,
                404: `APN profile not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Edit the status of multiple SIMs
     * @param customerId
     * @param status
     * @param requestBody
     * @returns BulkSimStatusUpdateResponse Successfully updated the SIM status
     * @throws ApiError
     */
    public static bulkUpdateSimStatus(
        customerId: number,
        status: string,
        requestBody: Array<BulkSimStatusUpdateRequest>,
    ): CancelablePromise<BulkSimStatusUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customers/{customerId}/sims/bulk-status/{status}',
            path: {
                'customerId': customerId,
                'status': status,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request data`,
                404: `SIM or site not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Provision multiple sites
     * @param customerId
     * @param requestBody
     * @returns BulkSimProvisionResponse Successfully provisioned the sites
     * @throws ApiError
     */
    public static bulkProvisionSite(
        customerId: number,
        requestBody: Array<BulkSimProvisionRequest>,
    ): CancelablePromise<BulkSimProvisionResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/service/api/v1/customers/{customerId}/sims/bulk-provision',
            path: {
                'customerId': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request data`,
                404: `Site not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Execute command on Network Elements (HSS/MME/SPGW/PCRF)
     * @param customerId
     * @param siteId
     * @param requestBody
     * @returns string Successfully executed the Command
     * @throws ApiError
     */
    public static executeNetworkCommand(
        customerId: number,
        siteId: number,
        requestBody: NetworkCommandRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/network-commands/customer/{customerId}/site/{siteId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `deviceType not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Creates a new UsimProfile for a customer's site
     * @param customerId
     * @param siteId
     * @param requestBody
     * @returns UsimProfile UsimProfile created successfully
     * @throws ApiError
     */
    public static createUsimProfile(
        customerId: number,
        siteId: number,
        requestBody: UsimProfile,
    ): CancelablePromise<UsimProfile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/sims/profile',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input provided`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Uploads a list of Usim details in bulk from a file
     * @param customerId
     * @param siteId
     * @param formData
     * @returns Page Successfully uploaded the file
     * @throws ApiError
     */
    public static bulkUploadSims(
        customerId: number,
        siteId: number,
        formData?: {
            file?: Blob;
            textData?: string;
        },
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/sims/bulk-upload',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                404: `Usim not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves a list of all 4G QoS profiles for a specific customer's site
     * @param customerId
     * @param siteId
     * @param refresh
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @returns Page Successfully retrieved list of 4G QoS profiles
     * @throws ApiError
     */
    public static list4GQoSProfiles(
        customerId: number,
        siteId: number,
        refresh: boolean = false,
        page?: any,
        size?: any,
        sort?: any,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/qos/4g',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            query: {
                'refresh': refresh,
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                400: `Bad Request`,
                404: `No 4G QoS profiles found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Creates a new 4G QoS profile for a customer's site
     * @param customerId
     * @param siteId
     * @param requestBody
     * @returns Qos4g 4G QoS profile created successfully
     * @throws ApiError
     */
    public static create4GQoSProfile(
        customerId: number,
        siteId: number,
        requestBody: Qos4g,
    ): CancelablePromise<Qos4g> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/qos/4g',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input provided`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves a list of all 3G QoS profiles for a specific customer's site
     * @param customerId
     * @param siteId
     * @param refresh
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @returns Page Successfully retrieved list of 3G QoS profiles
     * @throws ApiError
     */
    public static list3GQoSProfiles(
        customerId: number,
        siteId: number,
        refresh: boolean,
        page?: any,
        size?: any,
        sort?: any,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/qos/3g',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            query: {
                'refresh': refresh,
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                400: `Bad Request`,
                404: `No 3G QoS profiles found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Creates a new 3G QoS profile for a customer's site
     * @param customerId
     * @param siteId
     * @param requestBody
     * @returns Qos3g 3G QoS profile created successfully
     * @throws ApiError
     */
    public static create3GQoSProfile(
        customerId: number,
        siteId: number,
        requestBody: Qos3g,
    ): CancelablePromise<Qos3g> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/qos/3g',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input provided`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves a list of all Advanced 3G QoS profiles for a specific customer's site
     * @param customerId
     * @param siteId
     * @param refresh
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @returns Page Successfully retrieved list of Advanced 3G QoS profiles
     * @throws ApiError
     */
    public static listAdvanced3GQoSProfiles(
        customerId: number,
        siteId: number,
        refresh: boolean,
        page?: any,
        size?: any,
        sort?: any,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/qos/3g/advanced',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            query: {
                'refresh': refresh,
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                400: `Bad Request`,
                404: `No Advanced 3G QoS profiles found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Creates a new Advanced 3G QoS profile for a customer's site
     * @param customerId
     * @param siteId
     * @param requestBody
     * @returns AdvancedQos3g Advanced 3G QoS profile created successfully
     * @throws ApiError
     */
    public static createAdvanced3GQoSProfile(
        customerId: number,
        siteId: number,
        requestBody: AdvancedQos3g,
    ): CancelablePromise<AdvancedQos3g> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/qos/3g/advanced',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input provided`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves all network components for a specific customer's site
     * @param customerId
     * @param siteId
     * @returns any Successfully retrieved network components
     * @throws ApiError
     */
    public static getSiteNetworkComponents(
        customerId: number,
        siteId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/components',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                404: `Site or Customer does not exist`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Creates a new network component for a specific customer's site
     * @param customerId
     * @param siteId
     * @param requestBody
     * @returns SiteNetworkComponentResponse Successfully created network component
     * @throws ApiError
     */
    public static createSiteNetworkComponent(
        customerId: number,
        siteId: number,
        requestBody: SiteNetworkComponentRequest,
    ): CancelablePromise<SiteNetworkComponentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/components',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input data`,
                404: `Site or Customer does not exist`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves a list of all APNs for a specific customer's site
     * @param customerId
     * @param siteId
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @returns Page Successfully retrieved list of APNs
     * @throws ApiError
     */
    public static listApns(
        customerId: number,
        siteId: number,
        page?: any,
        size?: any,
        sort?: any,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/apn',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                400: `Bad Request`,
                404: `No APNs found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Creates a new APN configuration for a specific customer's site
     * @param customerId
     * @param siteId
     * @param requestBody
     * @returns Apn APN configuration created successfully
     * @throws ApiError
     */
    public static createApn(
        customerId: number,
        siteId: number,
        requestBody: Apn,
    ): CancelablePromise<Apn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/apn',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input provided`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves list of all network commands
     * @param providerId
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @returns Page Successfully retrieved list of all network commands
     * @throws ApiError
     */
    public static getAllNetworkCommands(
        providerId: string,
        page?: any,
        size?: any,
        sort?: any,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/network-commands/provider/{providerId}',
            path: {
                'providerId': providerId,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                400: `Bad Request`,
                404: `deviceType not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves the USIM Session Information
     * @param customerId
     * @param siteId
     * @param simId
     * @returns UsimSessionInfoResponse Successfully retrieved USIM Session Information
     * @throws ApiError
     */
    public static getUsimSessionInfo(
        customerId: number,
        siteId: number,
        simId: number,
    ): CancelablePromise<UsimSessionInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/sims/{simId}/session-info',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'simId': simId,
            },
            errors: {
                400: `Bad Request`,
                404: `No Session Information found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves the USIM Event History
     * @param customerId
     * @param siteId
     * @param simId
     * @returns UsimEventHistoryListResponse Successfully retrieved USIM Event History
     * @throws ApiError
     */
    public static getUsimEventHistory(
        customerId: number,
        siteId: number,
        simId: number,
    ): CancelablePromise<UsimEventHistoryListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/sims/{simId}/event-history',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'simId': simId,
            },
            errors: {
                400: `Bad Request`,
                404: `No Event History found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves the USIM Connection Status
     * @param customerId
     * @param siteId
     * @param simId
     * @returns UsimConnectionStatusResponse Successfully retrieved USIM Connection Status
     * @throws ApiError
     */
    public static getUsimConnectionStatus(
        customerId: number,
        siteId: number,
        simId: number,
    ): CancelablePromise<UsimConnectionStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/sims/{simId}/connection-status',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'simId': simId,
            },
            errors: {
                400: `Bad Request`,
                404: `No Connection Status found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves audit log of specific sim on specific site from a specific customer
     * @param customerId
     * @param siteId
     * @param simId
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @returns Page Successfully retrieved asim audit log
     * @throws ApiError
     */
    public static getUsimSiteAuditLog(
        customerId: number,
        siteId: number,
        simId: number,
        page?: any,
        size?: any,
        sort?: any,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/sims/{simId}/audit',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'simId': simId,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                400: `Bad Request`,
                404: `Usim, Site, or Customer does not exist`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Sync Devices for a specific customer's site
     * @param customerId
     * @param siteId
     * @param refresh
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @returns SyncLogResponse Successfully retrieved list of devices and network profiles
     * @throws ApiError
     */
    public static listUsimSyncLogBySiteId(
        customerId: number,
        siteId: number,
        refresh: boolean,
        page?: any,
        size?: any,
        sort?: any,
    ): CancelablePromise<SyncLogResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/sims/sync',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            query: {
                'refresh': refresh,
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                400: `Bad Request`,
                404: `No network profiles/devices found or customer/site not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves a list of all UsimProfiles for a specific customer's site
     * @param customerId
     * @param siteId
     * @param refresh
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @returns Page Successfully retrieved list of UsimProfiles
     * @throws ApiError
     */
    public static listUsimProfilesByCustomerSiteId(
        customerId: number,
        siteId: number,
        refresh: boolean,
        page?: any,
        size?: any,
        sort?: any,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/sims/profiles',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            query: {
                'refresh': refresh,
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                400: `Bad Request`,
                404: `No UsimProfiles found or customer/site not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves details of a specific UsimProfile for a customer's site
     * @param customerId
     * @param siteId
     * @param profileId
     * @returns UsimProfileResponse Successfully retrieved UsimProfile details
     * @throws ApiError
     */
    public static getUsimProfileDetails(
        customerId: number,
        siteId: number,
        profileId: number,
    ): CancelablePromise<UsimProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/sims/profiles/{profileId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'profileId': profileId,
            },
            errors: {
                400: `Bad Request`,
                404: `UsimProfile not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves details of a specific 4G QoS profile for a customer's site
     * @param customerId
     * @param siteId
     * @param profileId
     * @returns Qos4gResponse Successfully retrieved 4G QoS profile details
     * @throws ApiError
     */
    public static get4GQoSProfileDetails(
        customerId: number,
        siteId: number,
        profileId: number,
    ): CancelablePromise<Qos4gResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/qos/4g/{profileId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'profileId': profileId,
            },
            errors: {
                400: `Bad Request`,
                404: `4G QoS profile not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves details of a specific 3G QoS profile for a customer's site
     * @param customerId
     * @param siteId
     * @param profileId
     * @returns Qos3gResponse Successfully retrieved 3G QoS profile details
     * @throws ApiError
     */
    public static get3GQoSProfileDetails(
        customerId: number,
        siteId: number,
        profileId: number,
    ): CancelablePromise<Qos3gResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/qos/3g/{profileId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'profileId': profileId,
            },
            errors: {
                400: `Bad Request`,
                404: `3G QoS profile not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves details of a specific Advanced 3G QoS profile for a customer's site
     * @param customerId
     * @param siteId
     * @param profileId
     * @returns AdvancedQos3gResponse Successfully retrieved Advanced 3G QoS profile details
     * @throws ApiError
     */
    public static getAdvanced3GQoSProfileDetails(
        customerId: number,
        siteId: number,
        profileId: number,
    ): CancelablePromise<AdvancedQos3gResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/qos/3g/advanced/{profileId}',
            path: {
                'customerId': customerId,
                'siteId': siteId,
                'profileId': profileId,
            },
            errors: {
                400: `Bad Request`,
                404: `Advanced 3G QoS profile not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves a list of all APN profiles for a specific customer's site
     * @param customerId
     * @param siteId
     * @param refresh
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @returns Page Successfully retrieved list of APN profiles
     * @throws ApiError
     */
    public static listApnProfiles(
        customerId: number,
        siteId: number,
        refresh: boolean,
        page?: any,
        size?: any,
        sort?: any,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sites/{siteId}/apn/profile',
            path: {
                'customerId': customerId,
                'siteId': siteId,
            },
            query: {
                'refresh': refresh,
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                400: `Bad Request`,
                404: `No APN profiles found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Retrieves a list of all SIMs for a specific customer
     * @param customerId
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param name
     * @param imsi
     * @param msisdn
     * @param profile
     * @param dateActivated
     * @param createdAt
     * @param siteIds
     * @param statuses
     * @param imeiLockEnabled
     * @param searchTerm
     * @param defaultSort
     * @returns DeviceSearchResponse Successfully retrieved list of SIMs
     * @throws ApiError
     */
    public static listAllSimsByCustomer(
        customerId: number,
        page?: any,
        size?: any,
        sort?: any,
        name?: string,
        imsi?: string,
        msisdn?: string,
        profile?: string,
        dateActivated?: string,
        createdAt?: string,
        siteIds?: Array<number>,
        statuses?: Array<UsimStatus>,
        imeiLockEnabled?: boolean,
        searchTerm?: string,
        defaultSort?: boolean,
    ): CancelablePromise<DeviceSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service/api/v1/customers/{customerId}/sims',
            path: {
                'customerId': customerId,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'name': name,
                'imsi': imsi,
                'msisdn': msisdn,
                'profile': profile,
                'dateActivated': dateActivated,
                'createdAt': createdAt,
                'siteIds': siteIds,
                'statuses': statuses,
                'imeiLockEnabled': imeiLockEnabled,
                'searchTerm': searchTerm,
                'defaultSort': defaultSort,
            },
            errors: {
                400: `Bad Request`,
                404: `Customer not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }
}
