/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ImeiLockRequest = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        usimIds: {
            type: 'array',
            contains: {
                type: 'number',
                format: 'int64',
            },
            isRequired: true,
        },
        siteId: {
            type: 'number',
            format: 'int64',
        },
    },
} as const;
