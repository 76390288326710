/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $BulkSimProvisionRequest = {
    properties: {
        version: {
            type: 'number',
            format: 'int64',
        },
        simId: {
            type: 'number',
            isRequired: true,
            format: 'int64',
            minimum: 1,
        },
        profileId: {
            type: 'number',
            isRequired: true,
            format: 'int64',
            minimum: 1,
        },
        siteId: {
            type: 'number',
            isRequired: true,
            format: 'int64',
            minimum: 1,
        },
        status: {
            type: 'UsimStatus',
            isRequired: true,
        },
    },
} as const;
