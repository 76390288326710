import { ApiRequestOptions } from '@/api/core/ApiRequestOptions';
import AuthService from '@/service/authentication-service';
import SystemSettings from '@/service/system-settings';
import { User, clearState, setUser } from '@/store/customerManagement';
import { clearNotification } from "@/store/submitNotification";
import { ROLES_CUSTOMER_ORDERED, ROLES_TELUS_ORDERED } from '@/types/UserRole';
import { getLastCommit } from "git-last-commit";
import getConfig from 'next/config';
import { AnyAction, Dispatch } from 'redux';
import { OpenAPI, Role, RoleType, UserApiService } from '../generated';
import { store } from '../store';
import { EARLY_TOKEN_REFRESH_INTERVAL } from './constants';
import { clearAllNotifications } from "../store/submitNotification";


const { publicRuntimeConfig = {} } = getConfig() || {};

export const apiBase = async (): Promise<string> => {
  if (typeof window === "undefined") {
    return publicRuntimeConfig.NEXT_PUBLIC_API_BASE;
  } else if(window.location.hostname === 'localhost') {
    return 'http://localhost:8080';
  } else {
    return 'https://' + window.location.hostname;
  }
}

export const checkTokenExpiration = async (): Promise<void> => {
  var user = store.getState()?.customerManagement?.user;
  const currentTime = Math.floor(new Date().getTime() / 1000);
  // check if refresh token is there and not expired
  if (!document.hidden && !!user?.nextTokenRefreshTime && user?.nextTokenRefreshTime <= currentTime) {
    AuthService.lock = true; // enable the lock so that any new request fired after has to wait for the new access,refresh tokens
    await AuthService.requestNewToken();
  }
};


export const fetchUser = async (): Promise<User> => {  
  // AuthService.refreshPromise
  return await UserApiService.userToken().then((userTokenDetails) => {    
      if(userTokenDetails?.expiryTime) {
        return {
          id: userTokenDetails?.username,
          name: userTokenDetails?.name,
          username: userTokenDetails?.username,
          email: userTokenDetails?.email,
          customerId: userTokenDetails.customerId,
          role: getRole(userTokenDetails),
          expiryTime: userTokenDetails?.expiryTime,
          nextTokenRefreshTime: userTokenDetails?.expiryTime - EARLY_TOKEN_REFRESH_INTERVAL
        } as User;
      }
    });
}

export function getRole(decodedToken: any): Role {
  const roles: String[] = decodedToken.roles;

  for (const role of ROLES_TELUS_ORDERED) {
      const filteredAssets = roles.filter(asset => asset === role);
      if (filteredAssets.length > 0) {
          return { label: filteredAssets[0], name: filteredAssets[0], type: RoleType.TELUS } as Role;
      }
  }
  for (const role of ROLES_CUSTOMER_ORDERED) {
      const filteredAssets = roles.filter(asset => asset === role);
      if (filteredAssets.length > 0) {
          return { label: filteredAssets[0], name: filteredAssets[0], type: RoleType.CUSTOMER } as Role;
      }
  }
  throw new Error("No matching role found");
}

//  this ensures only one instance of unresolved promise is there in the application
export const waitIndefinitely = (): Promise<void> => {
  if (!AuthService.indefiniteWaitPromise) {
      // This promise never resolves
      AuthService.indefiniteWaitPromise = new Promise(() => {
      });
  }
  return AuthService.indefiniteWaitPromise;
};

export const initApp = () => {
  // App Initialization
  apiBase().then(base => {
    OpenAPI.BASE = base;
  });
  
  OpenAPI.HEADERS = async(options: ApiRequestOptions) => {
    
    await checkTokenExpiration();
    //  if the lock is enabled then the new requests have to wait for the refreshPromise to gets completed
    if (AuthService.lock && AuthService.refreshPromise !== null) {
      await AuthService.refreshPromise;
    }
    // to ensure other requests doesnt proceed with their api requests if refresh token request fails
    if(AuthService.error) {
      await waitIndefinitely();
    }
    return getHeaders(options);
  }
  OpenAPI.WITH_CREDENTIALS = true;
}

export async function getHeaders(options: ApiRequestOptions): Promise<typeof headers> {
  let headers: any;
  return headers;
}

export const getRedirectUrl = (user: User) => {
  return user ? (user.role.type === RoleType.TELUS ? '/' : `/customer/${user.customerId}`) : '/login';
}

export function getGitCommit() {
  return new Promise((res, rej) => {
      getLastCommit((err, commit) => {
          if (err) return rej(err);
          return res(commit);
      });
  });
}

export const logoutUser = async (dispatch: Dispatch<AnyAction>, user: User) => {
  console.log("logging out");
  dispatch(clearNotification());  
  const headers = new Headers();
  headers.append('pragma', 'no-cache');
  headers.append('cache-control', 'no-cache');
  const init = { method: 'GET', headers: headers };
  await UserApiService.logout().then(resp => {
    clearSavedState(dispatch);
  }).catch(err => {
    clearSavedState(dispatch);
  })
;  
};

export const clearSavedState = (dispatch: Dispatch<AnyAction>) => {
  console.log("clearing state")
  // clear store
  dispatch(setUser(null));

  // clear notification history for session
  dispatch(clearAllNotifications());

  // clear local storage
  clearState();
}

export const getSsoLoginUrl = (origin: string): string => {
  return SystemSettings.settings.SSO_LOGIN_URL + '?response_type=code&client_id=' + SystemSettings.settings.CLIENT_ID + '&redirect_uri=' + origin + SystemSettings.settings.authorizationCodeUrl + '&scope=' + SystemSettings.settings.SCOPE;
}
