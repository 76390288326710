import { TableType } from '@/types/Table';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import type { RootState } from './index'
import { CustomerResponse, DeviceSearchResponse, Page, Role, SyncLogResponse, UserStatus, Site, SiteResponse } from '@/api/index'
import { defaultPageable, emptyTableState, localNoLoginState, omInitialState } from './customerManagement.local';
import SystemSettings from '@/service/system-settings';
import { set } from 'date-fns';

export interface RefreshSite {
  siteId?: number
  refresh: boolean
  tableType?: string
}

export class Pageable {
  page: number = 0;
  size?: number = 20;
  sort?: Array<string>;
}

export class User {
  id: string = '';
  name: string = '';
  username: string = '';
  email?: string = '';
  role?: Role;
  expiryTime?: number = null;
  nextTokenRefreshTime?: number = null;
  isAuthenticated: boolean = false;
  customerId?: number;
  sites?: SiteResponse[];
}

export interface DeviceSiteDetails {
  profileName?: string;
  siteName?: string[];
  status?: string[];
  dateActivated?: string;
  createdAt?: string;
}

export interface DeviceSearchFields {
  name?: string;
  imsi?: string;
  msisdn?: string;
  createdAt?: string;
  profileName?: string;
  siteName?: string[];
  status?: string[];
  dateActivated?: string;
  imeiLock?: string;
}

export interface CustomerSearchFields {
  customerName?: string;
  id?: string;
  ban?: string;
  customerStatus?: string[];
}

export interface UserRoleFields {
  label?: string;
}

export interface UserSearchFields {
  name?: string;
  role?: UserRoleFields;
  status?: UserStatus;
  username?: string;
}

export interface GeneralSearchQuery {
  searchQuery?: string;
}

export type AdvancedSearchParams =
  | UserSearchFields
  | CustomerSearchFields
  | DeviceSearchFields;
// export interface AdvancedSearchParams extends UserSearchFields, CustomerSearchFields, DeviceSearchFields {}

export interface ToolbarSettings {
  selectedSliceIds: string[];
}
export interface ServiceNotification {
  [nsiId: string]: string;
}

export interface FavouriteSite {
  siteId: number;
  siteName: string;
}

interface DisableSyncButton {
  [key: string]: boolean
};

export interface AppSettings {
  refreshSite: RefreshSite
  defaultLocale: 'en' | 'fr'
  toolbar: ToolbarSettings
  createSliceModalOpen: boolean
  isBeingSubmitted: boolean
  isSubmitted: boolean
  isSuccess: boolean
  successMessage: string
  errorMessage: string
  isLoading: boolean
  tableType: string
  others: any
  advancedSearchPanelState: boolean; // New state property
  disableSyncButton: DisableSyncButton
  deploymentPlatform: string
  siteLoading: boolean
}

export interface TableState {
  currentPage?: Page | DeviceSearchResponse | SyncLogResponse;
  pageSize?: number;
  sortKeys?: string[];
  advancedSearchParams?: AdvancedSearchParams
  advancedSearchPanelState?: boolean; // New state property
  generalSearchQuery?: GeneralSearchQuery
  cachedData?: any[]
}

export interface DeviceUploadPreview {
  imsi?: string;
  iccid?: string
  name?: string
  enc_ki?: string
  enc_opc?: string
  op?: string
  msisdn?: string
  transport_key_index?: string
  transport_key_name?: string
  pin1?: string
  pin2?: string
  puk1?: string
  puk2?: string
}

export interface DataTableState {
  [key: string]: TableState
}

export interface ApplicationVersions {
  api: String
  ui: String
}

export interface OrderManagementState {
  currentPageable: Pageable
  currentCustomerAuditLog: any
  selectedCustomer: CustomerResponse
  user: User;
  user2FAEnable: boolean
  allowedCustomerUserRoles: Role[]
  allowedTelusUserRoles: Role[]
  telusUserRoles: Role[]
  customerUserRoles: Role[]
  notifications: ServiceNotification
  favouriteSite: FavouriteSite
  settings: AppSettings
  deviceUploadPreview: DeviceUploadPreview[]
  dispatch: (state: any) => any
  advancedSearchParams: AdvancedSearchParams
  generalSearchQuery: GeneralSearchQuery
  dataTableState: DataTableState
  applicationVersions: ApplicationVersions
}
export const initialState = omInitialState;

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(window.location.origin + ":state");
    // console.log(`serializedState: ${serializedState}`);

    if (serializedState === null) {
      return initialState;
    }

    return JSON.parse(serializedState).customerManagement;
  } catch (err) {
    return initialState;
  }
}

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(window.location.origin + ":state", serializedState);
  } catch (err) {
  }
}

export const clearState = () => {
  try {
    localStorage.removeItem(window.location.origin + ":state");
    console.log('Local storage cleared successfully for:' + window.location.origin + ":state");
    // console.log('Local storage after clear:' + localStorage.getItem(window.location.origin + ":state"));

  } catch (err) {
    console.log('Failed to clear local storage:', err);
  }
}


const customerManagement = createSlice({
  name: 'customerManagement',
  initialState,
  reducers: {
    resetCustomerDataStates(state) { // clear the redux for customer specific data
      state.deviceUploadPreview = initialState.deviceUploadPreview;
      state.dataTableState = initialState.dataTableState;
      state.currentCustomerAuditLog = initialState.currentCustomerAuditLog;
      state.advancedSearchParams = initialState.advancedSearchParams;
      state.generalSearchQuery = initialState.generalSearchQuery;
      state.settings.advancedSearchPanelState = initialState.settings.advancedSearchPanelState;
    },
    setDeviceUploadPreview(state, action: PayloadAction<DeviceUploadPreview[]>) {
      state.deviceUploadPreview = action.payload;
    },
    setSelectedCustomer(state, action: PayloadAction<CustomerResponse>) {
      state.selectedCustomer = action.payload;
    },
    setCurrentCustomerAuditLog(state, action: PayloadAction<any>) {
      state.currentCustomerAuditLog = action.payload;
    },
    setCurrentPageable(state, action: PayloadAction<Pageable>) {
      state.currentPageable = action.payload
    },
    setToolbarSettings(state, action: PayloadAction<ToolbarSettings>) {
      state.settings.toolbar = { ...state.settings.toolbar, ...action.payload };
    },
    setAppSettings(state, action: PayloadAction<AppSettings>) {
      state.settings = { ...state.settings, ...action.payload };
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setUser2FAEnable(state, action: PayloadAction<any>) {
      state.user2FAEnable = action.payload;
    },
    setAllowedCustomerUserRoles(state, action: PayloadAction<Role[]>) {
      state.allowedCustomerUserRoles = action.payload;
    },
    setAllowedTelusUserRoles(state, action: PayloadAction<Role[]>) {
      state.allowedTelusUserRoles = action.payload;
    },
    setTelusUserRoles(state, action: PayloadAction<Role[]>) {
      state.telusUserRoles = action.payload;
    },
    setCustomerUserRoles(state, action: PayloadAction<Role[]>) {
      state.customerUserRoles = action.payload;
    },
    setAdvancedSearchParams(
      state,
      action: PayloadAction<AdvancedSearchParams>
    ) {
      state.advancedSearchParams = {
        ...state.advancedSearchParams,
        ...action.payload
      };
    },
    // New reducer for updating the generalSearchQuery
    setGeneralSearchQuery(state, action: PayloadAction<GeneralSearchQuery>) {
      state.generalSearchQuery = action.payload;
    },
    setNotifications(state, action: PayloadAction<{ nsiId: string; notification: string }>) {
      const { nsiId, notification } = action.payload;
      state.notifications[nsiId] = notification; // Direct mutation allowed by Immer
    },
    setFavouriteSite(state, action: PayloadAction<FavouriteSite>) {
      state.favouriteSite = action.payload;
    },
    setAdvancedSearchPanelState(state) {
      state.settings.advancedSearchPanelState = !state.settings.advancedSearchPanelState;
    },
    setTableState(state, action: PayloadAction<{id: TableType, state: TableState}>) {
      // console.log("state:", action.payload.state)
      state.dataTableState[action.payload.id] = { ...state.dataTableState[action.payload.id], ...action.payload.state };
    },
    setApplicationVersion(state, action: PayloadAction<ApplicationVersions>) {
      state.applicationVersions = action.payload
    }
  }
});

export const {
  setCurrentPageable,
  resetCustomerDataStates,
  setDeviceUploadPreview,
  setSelectedCustomer,
  setCurrentCustomerAuditLog,
  setToolbarSettings,
  setAdvancedSearchParams,
  setGeneralSearchQuery,
  setAppSettings,
  setUser,
  setUser2FAEnable,
  setAllowedCustomerUserRoles,
  setAllowedTelusUserRoles,
  setTelusUserRoles,
  setCustomerUserRoles,
  setNotifications,
  setFavouriteSite,
  setAdvancedSearchPanelState,
  setTableState,
  setApplicationVersion
} = customerManagement.actions

export const useCurrentPageable = () =>
  useSelector<RootState, Pageable>((state) => state.customerManagement.currentPageable || defaultPageable)
export const useSelectedCustomerAuditLog = () =>
  useSelector<RootState, any>((state) => state.customerManagement.currentCustomerAuditLog)
export const useDeviceUploadPreview = () =>
  useSelector<RootState, DeviceUploadPreview[]>((state) => state.customerManagement.deviceUploadPreview)
export const useToolBarSettings = () =>
  useSelector<RootState, ToolbarSettings>((state) => state.customerManagement.settings.toolbar)
export const useAppSettings = () =>
  useSelector<RootState, AppSettings>((state) => state.customerManagement.settings)
export const useSelectedCustomer = () =>
  useSelector<RootState, CustomerResponse>((state) => state.customerManagement.selectedCustomer)
export const useAdvancedSearchParams = () =>
  useSelector<RootState, AdvancedSearchParams>((state) => {
    return state.customerManagement.advancedSearchParams;
  });
export const useGeneralSearchQuery = () =>
  useSelector<RootState, GeneralSearchQuery>((state) => {
    return state.customerManagement.generalSearchQuery;
  });
export const useUser = () =>
  useSelector<RootState, User>((state) => {
    return state.customerManagement.user
  })
export const useUser2FAEnable = () =>
  useSelector<RootState, any>((state) => {
    return state.customerManagement.user2FAEnable;
  });
export const useCurrentUserRole = () =>
  useSelector<RootState, Role>((state) => {
    return state.customerManagement.user?.role
  })
export const useAllowedCustomerUserRoles = () =>
  useSelector<RootState, Role[]>((state) => {
    return state.customerManagement.allowedCustomerUserRoles
  })
export const useAllowedTelusUserRoles = () =>
  useSelector<RootState, Role[]>((state) => {
    return state.customerManagement.allowedTelusUserRoles
  })
export const useTelusUserRoles = () =>
  useSelector<RootState, Role[]>((state) => {
    return state.customerManagement.telusUserRoles;
  });
export const useCustomerUserRoles = () =>
  useSelector<RootState, Role[]>((state) => {
    return state.customerManagement.customerUserRoles;
  });
export const useNotifications = () =>
  useSelector<RootState, ServiceNotification>((state) => {
    return state.customerManagement.notifications
  })
export const useFavouriteSite = () =>
  useSelector<RootState, FavouriteSite>((state) => {
    return state.customerManagement.favouriteSite
  })
export const useIsAutneticated = () =>
  useSelector<RootState, boolean>((state) => {
  return !!state.customerManagement.user && state.customerManagement.user.isAuthenticated
  })
export const useIsTokenExpired = () =>
  useSelector<RootState, boolean>((state) => {
    const currentTime = Math.floor(new Date().getTime() / 1000); 
    return !state.customerManagement.user || state.customerManagement.user.nextTokenRefreshTime <= (currentTime);
  })
export const useAdvancedSearchPanelState = () =>
  useSelector<RootState, boolean>((state) => state.customerManagement.settings.advancedSearchPanelState);

export const useTableState = (id: TableType) =>
  useSelector<RootState, TableState>((state) => {
    return state.customerManagement.dataTableState?.[id] || emptyTableState
  })
export const useUserTokenExpiryTime = () =>
  useSelector<RootState, number>((state) => {
    return state.customerManagement.user?.expiryTime
  })

  export const useApplicationVersions = () =>
  useSelector<RootState, ApplicationVersions>((state) => {
    return state.customerManagement.applicationVersions
  })

export default customerManagement
