/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SiteNetworkComponent = {
    properties: {
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        createdBy: {
            type: 'string',
        },
        updatedAt: {
            type: 'string',
            format: 'date-time',
        },
        updatedBy: {
            type: 'string',
        },
        version: {
            type: 'number',
            format: 'int64',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        name: {
            type: 'string',
        },
        description: {
            type: 'string',
        },
        url: {
            type: 'string',
        },
        primaryComponent: {
            type: 'boolean',
        },
        hostname: {
            type: 'string',
        },
        networkType: {
            type: 'NetworkType',
        },
        site: {
            properties: {
            },
        },
    },
} as const;
