import { useIsAutneticated, useUser } from "@/store/customerManagement";
import { topMenuTheme } from "@/styles/theme/themes";
import { IconButton, StackWrap, Typography, Icon, Box, StackView, Button, Spacer, A11yText, Divider } from "@telus-uds/components-web";
import { HeadMale } from '@telus-uds/palette-allium/build/web/icons';
import TextIconButton from "../ToolBar/TextIconButton";
import {
  StatusSuccess,
  StatusError,
  StatusWarning,
  Clipboard,
  Time,
  Delete
} from '@telus-uds/palette-allium/build/web/icons';
import { useEffect, useState, useRef, Key } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
// @ts-expect-error
import { Listbox } from '@telus-uds/components-base';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../store";
import NotificationIcon from "../../NotificationIcon";
import { clearAllNotifications } from "@/store/submitNotification";

interface TopMenuProps {
  handleLoginPage: () => void;
}

const DropdownWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 8px;
  border: 2px solid #ddd;
  background-color: #fff;
  z-index: 1000;
  width: 400px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  max-height: 0;
`;

const IconButtonWrapper = styled.div`
  position: relative;
  z-index: 2147483646;
`;

const NotificationCount = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  border-radius: 50%;
  padding: 2px 5px;
  z-index: 2147483647;
`;

const formatLocalTimeWithSeconds = (date: string, normalizedTime?: boolean) => {
  const utcDate = new Date(date);

  if (normalizedTime) {
    return utcDate.toISOString().replace('T', ' ').replace('Z', ' UTC');
  }

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return utcDate.toLocaleString("en-US", {
    timeZone: userTimeZone,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour12: true,
  }).replace(/,\s+/, ' ');
};

export const NotificationEntry = ({ notification }: { notification: { type: string; message: string; timestamp: string; traceId?: string; details?: { [key: string]: any } | null } }) => {
  const handleCopyToClipboard = () => {
    const textToCopy =
      `message: ${notification.message}\n` +
      (notification.type === 'error' ? `error code: ${notification.traceId ? notification.traceId : "N/A"}\n` : '') +
      `timestamp: ${formatLocalTimeWithSeconds(notification.timestamp, true)}` + 
      (notification.details ? `details: ${JSON.stringify(notification.details, null, 2)}` : '');

    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <Box space={1}>
      <StackView space={2}>
        <Typography>{notification.message}</Typography>
        {(notification.type === 'error' || notification.type === 'warning') && (
          <StackView>
            <Typography>Error Code: {notification.traceId ? notification.traceId : "N/A"}</Typography>
            {notification.details && (
              <Typography>
                {JSON.stringify(notification.details, null, 2)}
              </Typography>
            )}
          </StackView>
        )}
        <Button icon={Clipboard} onPress={handleCopyToClipboard} variant={{ size: 'small', priority: 'low' }}>
          Copy to clipboard
        </Button>
      </StackView>
    </Box>
  );
};

const TopMenu = ({ handleLoginPage }: TopMenuProps) => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAutneticated();
  const user = useUser();
  const allNotifications = useSelector((state: RootState) => state.notification.allNotifications);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const [currentNotificationCount, setCurrentNotificationCount] = useState(allNotifications.length);
  const [displayCount, setDisplayCount] = useState(15); // Number of notifications to display initially
  const iconButtonRef = useRef<HTMLDivElement | null>(null);
  const portalNodeRef = useRef<HTMLElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  if (!portalNodeRef.current) {
    portalNodeRef.current = document.createElement("div");
  }

  useEffect(() => {
    const portalNode = portalNodeRef.current;
    document.body.appendChild(portalNode);

    return () => {
      document.body.removeChild(portalNode);
    };
  }, []);

  useEffect(() => {
    setNewNotificationCount(allNotifications.length - currentNotificationCount);
  }, [allNotifications]);

  const updateDropdownPosition = () => {
    if (iconButtonRef.current) {
      const rect = iconButtonRef.current.getBoundingClientRect();
      let leftPosition = rect.right + window.scrollX - 333; // Align dropdown right side with button right side
      const dropdownWidth = 333; // Width of the dropdown
      const viewportWidth = window.innerWidth;

      // Ensure gap on right side
      if (leftPosition + dropdownWidth + 10 > viewportWidth) {
        leftPosition = viewportWidth - dropdownWidth - 10;
      }

      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: leftPosition,
      });
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      updateDropdownPosition();
      if (dropdownRef.current) {
        dropdownRef.current.style.maxHeight = "50vh";
        dropdownRef.current.style.overflowY = "auto";
      }
      window.addEventListener('resize', updateDropdownPosition);
      window.addEventListener('scroll', updateDropdownPosition);
    } else {
      if (dropdownRef.current) {
        dropdownRef.current.style.maxHeight = "0";
        dropdownRef.current.style.overflowY = "hidden";
      }
    }

    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
      window.removeEventListener('scroll', updateDropdownPosition);
    };
  }, [isDropdownOpen]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      iconButtonRef.current &&
      !iconButtonRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      setNewNotificationCount(0);
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleIconButtonPress = () => {
    if (isDropdownOpen) {
      setCurrentNotificationCount(allNotifications.length);
    }
    setIsDropdownOpen((prev) => !prev);
  };

  const handleScroll = () => {
    if (dropdownRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = dropdownRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        setDisplayCount((prev) => Math.min(prev + 5, allNotifications.length));
      }
    }
  };

  useEffect(() => {
    if (isDropdownOpen && dropdownRef.current) {
      dropdownRef.current.addEventListener("scroll", handleScroll);
    } else if (dropdownRef.current) {
      dropdownRef.current.removeEventListener("scroll", handleScroll);
    }

    return () => {
      if (dropdownRef.current) {
        dropdownRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isDropdownOpen]);

  const listboxItems = allNotifications.slice(0, displayCount).reverse().map((notification, index) => {
    let icon, color;
    if (notification.type === 'success') {
      icon = StatusSuccess;
      color = 'success';
    } else if (notification.type === 'error') {
      icon = StatusError;
      color = 'danger';
    } else if (notification.type === 'warning') {
      icon = StatusWarning;
      color = 'warning';
    }
  
    const formattedTime = formatLocalTimeWithSeconds(notification.timestamp);
    return {
      label: (
        <div style={{ display: 'inline-flex', alignItems: 'center', gap: '8px' }} key={index}>
          {index < allNotifications.length - currentNotificationCount && (
            <Icon icon={Time} variant={{ size: 'micro', color: 'default' }} />
          )}
          <Icon icon={icon} variant={{ size: 'micro', color }} />
          {`${formattedTime}`}
        </div>
      ),
      items: [
        {
          label: <NotificationEntry notification={notification} />,
          href: "",
        }
      ],
    };
  });
  

  return (
    <StackWrap space={3} tokens={{ justifyContent: "flex-end" }}>
      {isAuthenticated && user && (
        <TextIconButton
          theme={topMenuTheme}
          iconTheme={topMenuTheme}
          icon={HeadMale}
          content={`${user?.name}`}
          disabled={true}
          disableTheme={topMenuTheme}
          onClick={() => {
            return false;
          }}
        />
      )}
      <TextIconButton
        theme={topMenuTheme}
        iconTheme={topMenuTheme}
        content={isAuthenticated ? "Logout" : "Login"}
        onClick={() => {
          handleLoginPage();
        }}
      />
      <IconButtonWrapper ref={iconButtonRef}>
        <IconButton
          action="expand"
          onPress={handleIconButtonPress}
          icon={NotificationIcon}
          tokens={{ borderWidth: 0, outerBorderWidth: 0 }}
          variant={{ size: "large" }}
        />
        {newNotificationCount > 0 && (
          <NotificationCount>
            <Typography block variant={{ size: 'eyebrow', colour: 'brand2' }}>
              {newNotificationCount}
              <A11yText text={`${newNotificationCount} unread application notifications`} />
            </Typography>
          </NotificationCount>
        )}
        {isDropdownOpen &&
          ReactDOM.createPortal(
            <DropdownWrapper
              ref={dropdownRef}
              style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
            >
              {allNotifications.length === 0 ? (
                <Box space={2}>
                  <Typography variant={{ size: 'h4' }}>No notifications</Typography>
                </Box>
              ) : (
                <div style={{ margin: '3%' }}>
                  <Listbox items={listboxItems} />
                </div>
              )}
            </DropdownWrapper>,
            portalNodeRef.current
          )}
      </IconButtonWrapper>
    </StackWrap>
  );
};

export default TopMenu;
